import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Axios from "axios";
import Auth from "./package/Auth";
import store from './store/store';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

Vue.use(require('vue-moment'));

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.prototype.$http = Axios;
Vue.use(Auth);

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.forAdmin)) {
    if (!Vue.auth.isAuthenticated()) {
      next({
        path: "/admin/0/login",
      });
    } else next();
  } else if (to.matched.some((record) => record.meta.forManufactures)) {
    if (!Vue.auth.isAuthenticated()) {
      next({
        path: "/login",
      });
    } else next(); 
  } else if (to.matched.some((record) => record.meta.forDistributors)) {
    if (!Vue.auth.isAuthenticated()) {
      next({
        path: "/login",
      });
    } else next();
  } else if (to.matched.some((record) => record.meta.forRetailers)) {
    if (!Vue.auth.isAuthenticated()) {
      next({
        path: "/login",
      });
    } else next();
  } else if (to.matched.some((record) => record.meta.forUsers)) {
    if (!Vue.auth.isAuthenticated()) {
      next({
        path: "/login",
      });
    } else next();
  } else if (to.matched.some((record) => record.meta.forGuest)) {
    if (Vue.auth.isAuthenticated()) {
      next({
        path: "/",
      });
    } else next();
  } else next();
});


new Vue({
  Auth,
  el: "#app",
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");

// Hide the loader when the app is loaded (you can use Vue's lifecycle hooks)
window.addEventListener('load', () => {
  const loader = document.getElementById('page-loader');
  if (loader) {
    loader.style.display = 'none';
  }
})

