export default function(Vue) {
  let authenticatedUser = {};

  Vue.auth = {
    //set token

    setToken(token, expiration) {
      localStorage.setItem("token", token);

      localStorage.setItem("expiration", expiration);
    },

    //get token

    getToken() {
      var token = localStorage.getItem("token");

      var expiration = localStorage.getItem("expiration");

      if (!token || !expiration) return null;
      else return token;

      //   if (Date.now() > parseInt(expiration)) {
      //     this.destroyToken();

      //     return null;
      //   } else return token;
    },

    //destroy token

    destroyToken() {
      localStorage.removeItem("token");
      localStorage.removeItem("expiration");
      localStorage.removeItem("userId");
    },

    //isAuthenticated

    isAuthenticated() {
      if (this.getToken()) return true;
      else return false;
    },

    //setAuthenticatedUser

    setAuthenticatedUser(data) {
      authenticatedUser = data;
    },

    //getAuthenticatedUser

    getAuthenticatedUser() {
      return authenticatedUser;
    },

    //getAuthenticatedUserID

    getAuthenticatedUserID() {
      return authenticatedUser.data.userId;
    },
  };

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth;
      },
    },
  });
}
