import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let routes = [
  {
    path: "/",
    name: "HomePage",
    //component: require("./components/HomePage.vue").default,
    component: () => import('./components/HomePage.vue'),
    meta: { hideNavigation: false },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import('./components/About.vue'),
    meta: { hideNavigation: false },
  },
  {
    path: "/consult-with-us",
    name: "Consult",
    component: () => import('./components/Consultation.vue'),
    meta: { hideNavigation: false },
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: () => import('./components/Contact.vue'),
    meta: { hideNavigation: false },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import('./components/Feedback.vue'),
    meta: { hideNavigation: false },
  },
  {
    path: "/stores",
    name: "pharmaStores",
    component: () => import('./components/Stores.vue'),
  },
  {
    path: "/store/0/:storeUserId",
    name: "pharmaUserStores",
    component: () => import('./components/UserStore.vue'),
  },
  {
    path: "/trade-assurance",
    name: "pharmaTradeAssurance",
    component: () => import('./components/TradeAssurance.vue'),
  },
  {
    path: "/payment-information",
    name: "pharmaPaymentInformation",
    component: () => import('./components/PaymentInfo.vue'),
  },
  {
    path: "/category/:categoryName/:categoryId",
    name: "pharmaProductCategory",
    component: () => import('./components/products/ProductCategory.vue'),
  },
  {
    path: "/retailers/store",
    name: "pharmaRetailerStores",
    component: () => import('./components/RetailersStores.vue'),
  },
  {
    path: "/distributors/store",
    name: "pharmaDistributorStores",
    component: () => import('./components/DistributorsStores.vue'),
  },
  {
    path: "/system-manufacturers",
    name: "pharmaManufacturers",
    component: () => import('./components/Manufacturers.vue'),
  },
  {
    path: "/system-distributors",
    name: "pharmaDistributors",
    component: () => import('./components/Distributors.vue'),
  },
  {
    path: "/system-retailers",
    name: "pharmaRetailers",
    component: require("./components/Retailers.vue").default,
  },
  {
    path: "/email-verification/en/success",
    name: "VerificationSuccess",
    component: () => import('./components/auth/VerificationSuccess.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },
  {
    path: "/login",
    name: "pharmaLogin",
    component: () => import('./components/auth/Login.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },
  {
    path: "/reset/password-link",
    name: "pharmaResetPasswordLink",
    component: () => import('./components/auth/ResetPasswordLink.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },
  {
    path: "/reset/password-link/:userId",
    name: "pharmaResetPassword",
    component: () => import('./components/auth/ResetPassword.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },
  {
    path: "/backup-code/verification/:email",
    name: "pharmaBackupCodeVerification",
    component: () => import('./components/auth/BuCodeVerification.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },
  {
    path: "/2fa/code/verification/:email",
    name: "pharma2faVerification",
    component: () => import('./components/auth/2faCodeVerification.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },
  {
    path: "/checkout",
    name: "pharmaCheckout",
    component: () => import('./components/Checkout'),
    meta: { hideFooter: true, isAuth: true },
  },
  {
    path: "/cart/overview",
    name: "pharmaCart",
    component: require("./components/Cart").default,
    meta: { isAuth: true },
  },
  {
    path: "/payment/successful",
    name: "pharmaPaymentSuccessful",
    component: require("./components/PaymentSuccess").default,
    meta: { isAuth: true },
  },
  {
    path: "/privacy-policy",
    name: "pharmaPrivacyPolicy",
    component: () => import('./components/PrivacyPolicy.vue'),
  },
  {
    path: "/terms-and-conditions",
    name: "pharmaTermsAndConditions",
    component: () => import('./components/TermsAndConditions.vue'),
  },
  {
    path: "/refund-policy",
    name: "pharmaRefundPolicy",
    component: () => import('./components/RefundPolicy.vue'),
  },
  

  //////////////Logistics Routes ///////////////////////////////
  {
    path: "/logistics-registration",
    name: "logisticsRegistration",
    component: () => import('./components/auth/LogisticRegistration.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },

  {
    path: "/logistics-login",
    name: "logisticsLogin",
    component: () => import('./components/auth/LogisticLogin.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },

  {
    path: "/logistics/dashbaord/:logisticsCompanyId",
    name: "logisticsDashboard",
    component: () => import('./components/logistic/Dashboard.vue'),
    meta: {
      hideFooter: true,
      hideNavigation: true,
      LogisticsNavigation: true 
    },
  },

  {
    path: "/logistics/orders/:logisticsCompanyId",
    name: "logisticsOrders",
    component: () => import('./components/logistic/Orders.vue'),
    meta: {
      hideNavigation: true,
      LogisticsNavigation: true 
    },
  },

  

  //////////////Products/Stores Routes//////////////////////////
  {
    path: "/product-detail/:productId",
    name: "pharmaSingleProduct",
    component: () => import('./components/products/SingleProduct.vue'),
  },

  {
    path: "/distributor/store-en/:storeid",
    name: "pharmaDistributorStore",
    component: () => import('./components/distributor/DistributorStore.vue'),
    meta: {
      forProduct: true,
      productShipment: true,
    },
  },

  {
    path: "/manufacturers/store-en/:storeid",
    name: "pharmaManufactureStore",
    component: () => import('./components/distributor/DistributorStore.vue'),
    meta: {
      forProduct: true,
      productShipment: true,
    },
  },

  {
    path: "/product-search",
    name: "pharmaProductSearch",
    component: () => import('./components/products/SearchProducts.vue'),
  },

  {
    path: "/product/categories",
    name: "pharmaProductCategories",
    component: () => import('./components/products/ProductCategories.vue'),
  },

  ///////////////// Professionals ///////////////
  {
    path: "/professional/dashboard/en/home",
    name: "pharmaProfessionalDashboard",
    component: () => import('./components/professional/ProfessionalDashboard.vue'),
    meta: { forProfessionals: true },
  },

  /////////////////Retailer Routes//////////////
  {
    path: "/registration",
    name: "pharmaRegistration",
    component: () => import('./components/auth/Registration.vue'),
  },
  {
    path: "/overview/retailer_dashboard/home",
    name: "pharmaRetailerDashboard",
    component: () => import('./components/retailer/RetailerDashboard.vue'),
    meta: { forRetailers: true },
  },
  {
    path: "/retailer/0/products",
    name: "pharmaRetailerProducts",
    component: () => import('./components/retailer/RetailerProducts.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/0/pos",
    name: "pharmaRetailerPos",
    component: () => import('./components/retailer/RetailerPos.vue'),
    meta: {
      forRetailers: true,
      hideFooter: true
    },
  },
  {
    path: "/retailer_account/orders",
    name: "pharmaRetailerOrders",
    component: () => import('./components/retailer/Orders.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/my-shop-orders",
    name: "pharmaRetailerShopOrders",
    component: () => import('./components/retailer/ShopOrders.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer_account/feedbacks",
    name: "pharmaRetailerFeedbacks",
    component: () => import('./components/retailer/Feedbacks.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/account/shipping_address",
    name: "pharmaRetailerShippingAddress",
    component: () => import('./components/retailer/ShippingAddress.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/account/settings",
    name: "pharmaRetailerSettings",
    component: () => import('./components/retailer/Settings.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/message-center",
    name: "pharmaRetailerMessageCenter",
    component: () => import('./components/retailer/MessageCenter.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/wallet",
    name: "pharmaRetailerWallet",
    component: () => import('./components/retailer/Wallet.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/wallet-history/all",
    name: "pharmaRetailerWalletHistory",
    component: () => import('./components/retailer/WalletHistory.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/retailer/referals/en",
    name: "pharmaRetailerReferals",
    component: () => import('./components/retailer/Referals.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/en/help-center",
    name: "pharmaRetailerHelpCenter",
    component: () => import('./components/retailer/HelpCenter.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/account/email_confirmation/:memberId/:email",
    name: "pharmaRetailerEmailUpdate",
    component: () => import('./components/retailer/EmailUpdate.vue'),
    meta: {
      forRetailers: true,
    },
  },
  {
    path: "/account/password_update/:email",
    name: "pharmaRetailerPasswordUpdate",
    component: () => import('./components/retailer/PasswordUpdate.vue'),
    meta: {
      forRetailers: true,
      forProduct: true,
    },
  },

  //////////////Distributors Routes////////////////////////
  {
    path: "/overview/distributor_dashboard/en/home",
    name: "pharmaDistributorDashboard",
    component: () => import('./components/distributor/DistributorDashboard.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },
  {
    path: "/en/distributor/orders",
    name: "pharmaDistributorOrders",
    component: () => import('./components/distributor/DistributorOrders.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },
  {
    path: "/en/distributor/0/products",
    name: "pharmaDistributorProducts",
    component: () => import('./components/distributor/DistributorProducts.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },
  {
    path: "/en/distributor/all/products",
    name: "pharmaDistributorAllProducts",
    component: () => import('./components/distributor/AllProducts.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },
  {
    path: "/edit/distributor/:distributorId",
    name: "pharmaEditDistributorStore",
    component: () => import('./components/distributor/DistributorStoreEdit.vue'),
    meta: { 
      forDistributorEdit: true, 
      forDistDashboard: true,
      forDistributors: true  
    },
  },
  {
    path: "/en/products-from-manufactures",
    name: "pharmaDistributorAllManufacturesProducts",
    component: () => import('./components/distributor/productFromManufactures.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },
  {
    path: "/en/distributor/account-settings",
    name: "pharmaDistributorSettings",
    component: () => import('./components/distributor/DistributorsSettings.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },
  {
    path: "/en/distributor/wallet",
    name: "pharmaDistributorWallet",
    component: () => import('./components/distributor/DistributorsWallet.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },
  {
    path: "/en/distributor/Shipping-details",
    name: "pharmaDistributorShipping",
    component: () => import('./components/distributor/DistributorsShipping.vue'),
    meta: { 
       forDistDashboard: true,
       forDistributors: true 
      },
  },

  //////////////Manufacture Routes////////////////////////
  {
    path: "/overview/manufactures_dashboard/en/home",
    name: "pharmaManufacturerDashboard",
    component: () => import('./components/manufacturer/ManufactureDashboard.vue'),
    meta: { 
      forManufactureDashboard: true,
      forManufactures:true 
    },
  },


  {
    path: "/en/manufacturers/0/orders-list",
    name: "pharmaManufacturerOrderList",
    component: () => import('./components/manufacturer/ManufacturersOrderList.vue'),
    meta: { 
      forManufactureDashboard: true,
      forManufactures:true 
    },
  },

  {
    path: "/0/manufacturers/products",
    name: "pharmaManufacturerProducts",
    component: () => import('./components/manufacturer/ManufacturersProducts.vue'),
    meta: { 
      forManufactureDashboard: true,
      forManufactures:true 
    },
  },

  {
    path: "/edit/manufacturer/:manufacturerId",
    name: "pharmaEditManufacturerStore",
    component: () => import('./components/manufacturer/ManufacturersStoreEdit.vue'),
    meta: { 
      forDistributorEdit: true, 
      forDistDashboard: true,
      forManufactures:true 
    },
  },

  {
    path: "/0/manufacturers/account-settings",
    name: "pharmaManufacturerAccountSettings",
    component: () => import('./components/manufacturer/ManufacturersSettings.vue'),
    meta: { 
      forManufactureDashboard: true,
      forManufactures:true 
    },
  },

  ////////////// Error Pages //////////////////////
  {
    path: "/unauthorized",
    name: "pharmaUnAuthorized",
    component: () => import('./components/errors/unauthorized.vue'),
    meta: { hideNavigation: true },
  },

  ///////////// Admin Routes ////////////////////////
  {
    path: "/admin/0/login",
    name: "pharmaAdminLogin",
    component: () => import('./components/auth/AdminLogin.vue'),
    meta: { hideFooter: true, hideNavigation: true },
  },
  {
    path: "/admin/dashboard/0/en",
    name: "pharmaAdminDashboard",
    component: () => import('./components/admin/AdminDashboard.vue'),
    meta: { 
      hideFooter: true, 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true
    },
  },
  {
    path: "/admin/all-orders",
    name: "pharmaAdminOrders",
    component: () => import('./components/admin/AdminOrdersModule.vue'),
    meta: { 
      hideFooter: true, 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true 
    },
  },
  {
    path: "/admin/all-categories",
    name: "pharmaAdminCategories",
    component: () => import('./components/admin/AdminCategories.vue'),
     meta: { 
      hideFooter: true, 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true 
    },
  },
  {
    path: "/admin/user-statistics",
    name: "pharmaAdminUserStatistics",
    component: () => import('./components/admin/AdminUserStatistic.vue'),
    meta: { 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true  
    },
  },
  {
    path: "/admin/all-products",
    name: "pharmaAdminProducts",
    component: () => import('./components/admin/AdminProductsModule.vue'),
    meta: { 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true   
    },
  },
  {
    path: "/admin/wallets",
    name: "pharmaAdminWallet",
    component: () => import('./components/admin/AdminWalletModule.vue'),
    meta: { 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true   
    },
  },
  {
    path: "/admin/0/professionals",
    name: "pharmaAdminProfessionals",
    component: () => import('./components/admin/AdminProfessionals.vue'),
    meta: { 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true   
    },
  },
  {
    path: "/admin/users-account/module",
    name: "pharmaAdminUsersAccount",
    component: () => import('./components/admin/AdminUserAccountModule.vue'),
    meta: { 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true   
    },
  },
  {
    path: "/admin/manage-logistics/module",
    name: "pharmaAdminLogistics",
    component: () => import('./components/admin/AdminLogisticsModule.vue'),
    meta: { 
      hideNavigation: true, 
      AdminNavigation: true,
      forAdmin:true   
    },
  },

  //////////////Users Routes////////////////////////

  {
    path: "/overview/user/dashboard/en",
    name: "pharmaUserDashboard",
    component: () => import('./components/user/UserDashboard.vue'),
    meta: { hideFooter: true, forUsers: true },
  },

  {
    path: "/prescription/dashboard/en",
    name: "pharmaPrescriptionDashboard",
    component: () => import('./components/user/UserPrescriptionDashboard.vue'),
    meta: { hideFooter: true, forUsers: true },
  },

  {
    path: "/upload/user-prescription",
    name: "pharmaUploadPrescription",
    component: () => import('./components/user/UserPrescriptionUpload.vue'),
    meta: { hideFooter: true, forUsers: true },
  },

  {
    path: "/en/user/0/orders-list",
    name: "pharmaUserOrderList",
    component: () => import('./components/user/UserOrderList.vue'),
    meta: { hideFooter: true, forUsers: true },
  },
  {
    path: "/user/en/wallet",
    name: "pharmaUserWallet",
    component: () => import('./components/user/UserWallet.vue'),
    meta: { hideFooter: true, forUsers: true },
  },
  {
    path: "/user/en/wallet/history",
    name: "pharmaUserWalletHistory",
    component: () => import('./components/user/UserWalletHistory.vue'),
    meta: { hideFooter: true, forUsers: true },
  },
  {
    path: "/user/feedbacks",
    name: "pharmaUserFeedbacks",
    component: () => import('./components/user/UserFeedback.vue'),
    meta: { hideFooter: true, forUsers: true },
  },
  {
    path: "/user/account-settings",
    name: "pharmaUserAccountSettings",
    component: () => import('./components/user/UserAccountSetting.vue'),
    meta: { hideFooter: true, forUsers: true },
  },
  {
    path: "/user/referals",
    name: "pharmaUserReferals",
    component: () => import('./components/user/UserReferal.vue'),
    meta: { hideFooter: true, forUsers: true },
  },
  {
    path: "/user/account/shipping_address",
    name: "pharmaUserShippingAddress",
    component: () => import('./components/user/UserShippingAddress.vue'),
    meta: { hideFooter: true, forUsers: true },
  },
  {
    path: "/twofa/2fa/backupcodes/:user_id",
    name: "pharma2FABackupcodes",
    component: () => import('./components/auth/BackupCodes.vue'),
    meta: { hideFooter: true, forUsers: true },
  }

];

export default new Router({
  routes,
  scrollBehavior() {
      return { x: 0, y: 0 };
  },
  //mode:'history'
});
