import Vue from 'vue';
import Vuex from 'vuex';
import Axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cartItems: [],
    productLists: [],
    retailerProducts: [],
    distributorProducts: [],
    productsLimit: 0,
    productsTotalPages: 0,
  },
  actions: {
    fetchProducts({ commit }) {
        this.$http.get(`${process.env.VUE_APP_URL}products`)
          .then((response) => {
            commit('SET_PRODUCTS', response.data.data);
          })
          .catch(error => {
            console.error(error);
          });
    },

    addToCart({ commit }, cartData) {
        const userId = localStorage.getItem("userId");
        Axios.post(`${process.env.VUE_APP_URL}cart/add/${userId}`, cartData)
        .then(response => {
          const cartData = response.data.data.CartItems;
          commit('ADD_TO_CART', cartData);
        })
        .catch(error => {
          console.error(error.response);
        });
    },

    getCartItems({commit}) {
        const userId = localStorage.getItem("userId");
        Axios.get(`${process.env.VUE_APP_URL}cart/${userId}`)
        .then((response) => {
            const allCartItems = response.data.data.CartItems;
            commit('SET_CART', allCartItems);
        });
    }
  },
  mutations: {
    SET_PRODUCTS(state, data) {
        state.productsLimit = data.limit;
        state.productsTotalPages = data.totalPages;
        
        data.rows.forEach(element => {
          if(element.User.Role.name === "Retailer"){
            state.productLists.push(element);
          } else if(element.User.Role.name === "Distributor"){
            state.retailerProducts.push(element);
          } else if(element.User.Role.name === "Manufacturer"){
            state.distributorProducts.push(element);
          }
        });
        
    },
    ADD_TO_CART(state, cartData) {
      state.cartItems.push(cartData);
    },
    SET_CART(state, allCartItems) {
        state.cartItems = allCartItems;
    }
  },
  getters: {
    getCartItemCount(state) {
        return state.cartItems.length;
    }
  }
});
