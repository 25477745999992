<template>
  <v-container fluid full class="mb-10">
    <v-row wrap>
        <v-col cols="12">
            <v-layout class="mb-1">
                <v-col cols="12" align="center">
                <v-img
                    src="/img/paymentConfirmation.png"
                     alt="Payment Confirmation"
                />
                </v-col>
            </v-layout>
            <v-layout wrap>
                <v-col cols="12" align="center">
                    <h1>Your payment was successful.</h1>
                    <h4 align="center">
                        A payment receipt have been sent to your email.
                    </h4>
                    <p>
                        <v-btn depressed @click.native="refresh" dark>
                            Continue
                        </v-btn>
                    </p>
                </v-col>
            </v-layout>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: "Checkout",
  data() {
    return {
      
    };
  },
  created() {
    this.setAuthenticatedUser(); 
  },
  methods: {
    refresh() {
    // navigate to the new page
      this.$router.push('/')
    },
   
  },
};
</script>

<style>
.buttonStyle {
  background-color: #72bd61 !important;
  border-radius: 5px;
  color: #ffffff !important;
}

.overviewContainer {
  background-color: #ffffff;
  padding: 10px;
}

</style>
