<template>
  <v-container fluid full>
    <v-row wrap class="overviewContainer mb-10">
      <v-col cols="12">
        <h3>Cart</h3>
        <v-row wrap class="reProduct mt-5" v-if="cartItems.length >= 1">
          <v-col cols="12">
            <v-layout wrap>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left text-h6">
                          PRODUCT
                        </th>
                        <th class="text-left text-h6">
                          PRODUCT NAME
                        </th>
                        <th class="text-left text-h6">
                          PRICE
                        </th>
                        <th class="text-left text-h6">
                          QUANTITY
                        </th>
                        <th class="text-left text-h6">
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in cartItems" :key="item.name">
                        <td>
                              <v-img
                                height="100px"
                                width="100px"
                                :src="item.Product.productImagePath"
                                contain
                              />
                             
                        </td>
                        <td>
                              <p class="text-h5" style="font-size:14px !important;">{{ item.Product.name }}</p>
                        </td>
                        <td>
                          <p class="text-h6" style="font-size:14px !important;">
                            {{ systemSetting.systemCurrency
                            }}{{ item.price }}
                          </p>
                        </td>
                        <td>
                          <v-icon
                            dark
                            color="#72bd61"
                            class="mr-3"
                            @click="decreamentCounter(item)"
                            >mdi-minus-circle</v-icon
                          >
                          {{ item.qty }}
                          <v-icon
                            dark
                            color="#72bd61"
                            class="ml-3"
                            @click="increamentCounter(item)"
                            >mdi-plus-circle</v-icon
                          >
                        </td>
                        <td>
                          <v-layout wrap>
                            <v-col cols="9">
                              <p class="text-h6" style="font-size:14px !important;">
                                {{ systemSetting.systemCurrency
                                }}{{ item.price * item.qty }}
                              </p>
                            </v-col>
                            <v-col cols="3">
                              <v-btn
                                icon
                                color="#DB2C2C"
                                @click="deleteProduct(item)"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-col>
                          </v-layout>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-layout>

            <v-divider></v-divider>

            <v-layout wrap>
              <v-col cols="12" md="5" align="left">
                <v-btn class="buttonStyle" @click="$router.go(-1)">
                  <v-icon>mdi-chevron-left</v-icon> Continue Shopping</v-btn
                >
              </v-col>
              <v-col cols="12" md="6" offset-md="1" align="right">
                <v-layout style="background: #F2F2F2;">
                  <v-col cols="12">
                    <v-layout>
                      <span><b>Subtotal</b></span>
                      <v-spacer></v-spacer>
                      <span
                        ><b
                          >{{ systemSetting.systemCurrency
                          }}{{ total.toFixed(2) }}</b
                        ></span
                      >
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout>
                      <span class="text-h4" style="font-size:16px !important;"><b>Total</b></span> 
                      <v-spacer></v-spacer>
                      <span class="text-h4" style="color:#F22121; font-size:16px !important;"
                        ><b
                          >{{ systemSetting.systemCurrency
                          }}{{ total.toFixed(2) }}</b
                        ></span
                      >
                    </v-layout>
                  </v-col>
                </v-layout>
                <v-layout>
                  <v-col cols="12">
                    <v-btn class="buttonStyle" block to="/checkout">
                      proceed to checkout</v-btn
                    >
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-layout v-show="cartItems.length <= 0">
          <v-col cols="12" align="center">
            <p>
              <v-icon size="150">mdi-cart-off</v-icon>
            </p>
            <p class="text-h5">
              Your Cart is empty
            </p>
            <p v-if="!isAuth">
              Already have an account?
              <v-btn text color="#72BD61">Login</v-btn> to see the items in your
              cart.
            </p>
            <p>
              <v-btn class="buttonStyle mt-3" :to="'/stores'">
                START SHOPPING
              </v-btn>
            </p>
          </v-col>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SingleProduct",
  components: {},
  props: {
    isAuth: Boolean,
  },
  data() {
    return {
      quantity: 1,
      cartItems: null,
      cartData: {
        productId: "",
        quantity: 1,
      },
      total: 0,
      systemSetting: {
        systemCurrency: "GH₵",
      },
      product: {
        productId: "",
      },
    };
  },
  created() {
    this.userCartDetails();
  },
  methods: {
    userCartDetails() {
      this.$http
        .get(
          `${process.env.VUE_APP_URL}cart/` + localStorage.getItem("userId")
        )
        .then((response) => {
          this.cartItems = response.data.data.CartItems;
          this.total = this.cartItems.reduce(
            (total, item) => total + +item.price * +item.qty,
            0
          );
        });
    },

    increamentCounter(item) {
      item.qty = item.qty + 1;

      let data = {
        productId: item.Product.productId,
        quantity: item.qty,
      };

      this.$http
        .post(
          `${process.env.VUE_APP_URL}cart/update/` +
            localStorage.getItem("userId"),
          data
        )
        .then(() => {
          this.total = this.cartItems.reduce(
            (total, item) => total + +item.price * +item.qty,
            0
          );
        });
    },

    decreamentCounter(item) {
      if (item.qty <= 0) {
        item.qty = 1;
      }

      item.qty = item.qty - 1;

      let data = {
        productId: item.Product.productId,
        quantity: item.qty,
      };

      this.$http
        .post(
          `${process.env.VUE_APP_URL}cart/update/` +
            localStorage.getItem("userId"),
          data
        )
        .then(() => {
          this.total = this.cartItems.reduce(
            (total, item) => total + +item.price * +item.qty,
            0
          );
        });
    },

    deleteProduct(item) {
      let data = {
        productId: item.Product.productId,
      };
      this.userId = localStorage.getItem("userId");
      this.$http
        .post(`${process.env.VUE_APP_URL}cart/` + this.userId, data)
        .then(() => {
          location.reload();
        });
    },
  },
};
</script>

<style>
.productStyle {
  padding: 0px 35px 15px 35px;
}

.reProduct {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.priceStyle {
  width: 80px;
  height: auto;
  background-color: black;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.spanStyle {
  font-size: 14px !important;
}

.priceTag {
  color: #72bd61;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
}

.buttonStyleGray {
  background: linear-gradient(192.88deg, #ffffff, #111212 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
}

.overviewContainer {
  background-color: #f2f2f2;
  padding: 10px;
}

.whiteBg {
  background-color: #ffffff;
  padding: 20px;
}

.greyBg {
  background-color: #0f0e0e;
  padding: 20px;
}
</style>
