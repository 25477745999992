<template>
  <div>
    <page-loader v-if="loading"></page-loader>
    <v-app>
      <v-app-bar
        app
        color="#FFFFFF"
        height="90"
        v-if="!$route.meta.hideNavigation"
      >
        <v-container class="py-0 fill-height">
          <router-link to="/">
                  <v-img
                    alt="System Logo"
                    class="shrink"
                    style="margin-right:20px !important;"
                    contain
                    :src="'img/logo.png'"
                    transition="scale-transition"
                    width="70"
                  />
          </router-link>

          <!-- User Dashboard Button -->
          <v-btn
            class="hidden-sm-and-down" 
            style="background-color:transparent !important;"
            v-if="isAuth && userMenu"
            text
          >
            <router-link  to="/overview/user/dashboard/en" style="text-decoration:none; color:#000000;">
              <v-icon color="#81C24B">mdi-home-city</v-icon> 
              <span> Dashboard</span>
            </router-link>
          </v-btn>

          <!-- Retailer Dashboard Button -->
          <v-btn
            class="hidden-sm-and-down" 
            style="background-color:transparent !important;"
            v-if="isAuth && retailerMenu"
            text
          >
            <router-link  to="/overview/retailer_dashboard/home" style="text-decoration:none; color:#000000;">
              <v-icon color="#81C24B">mdi-home-city</v-icon> 
              <span> Dashboard</span>
            </router-link>
          </v-btn>

          <!-- Manufacturer Dashboard Button -->
          <v-btn
            class="hidden-sm-and-down" 
            v-if="isAuth && manufactureMenu"
            text
          >
            <router-link  to="/overview/manufactures_dashboard/en/home" style="text-decoration:none; color:#000000;">
              <v-icon color="#81C24B">mdi-home-city</v-icon> 
              <span> Dashboard</span>
            </router-link>
          </v-btn>

          <!-- distributor Dashboard Button -->
          <v-btn
            class="hidden-sm-and-down" 
            v-if="isAuth && distributorMenu"
            text
          >
            <router-link  to="/overview/distributor_dashboard/en/home" style="text-decoration:none; color:#000000;">
              <v-icon color="#81C24B">mdi-home-city</v-icon> 
              <span> Dashboard</span>
            </router-link>
          </v-btn>

          <v-btn
            class="hidden-sm-and-down" 
            v-for="link in linksMenu"
            :key="link.id"
            text
          >
          <router-link :to="{ name: link.name }" style="text-decoration:none; color:#000000;">
            <v-icon color="#81C24B">{{link.icon}}</v-icon> 
            <span>{{ link.label }}</span>
          </router-link>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            class="hidden-sm-and-down"
            depressed
            color="#000000"
            style="color:#ffffff;"
          :to="{ name: 'pharmaLogin' }"
          v-if="!isAuth"
          >
            <span>Sign up/Login</span>
          </v-btn>

          <v-btn
            class="hidden-sm-and-down"
            depressed
            color="#000000"
            style="color:#ffffff;"
            @click="handleLogout"
          v-if="isAuth"
          >
            <span>Logout</span>
          </v-btn>

          <v-btn
            class="ma-2 ml-5"
            text
            icon
            :to="'/cart/overview'"
          >
              <v-badge
                class="mr-2"
                value="0"
                color="red"
                :content="cartItemCount"
                overlap
              >
                <v-icon class="mr-3">mdi-cart-outline</v-icon>
              </v-badge>
              <span style="color:#000000;">Cart</span>
          </v-btn> 

          <v-menu >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="hidden-md-and-up" text color="white" v-bind="attrs" v-on="on">
                <v-icon size="45" color="#000000">
                  mdi-dots-grid
                </v-icon>
              </v-btn>
            </template>
              <v-list>
                            <v-list-item 
                            v-for="link in linksMenu"
                            :key="link.id"
                            :to="{ name: link.name }"
                            >
                              <v-list-item-icon>
                                <v-icon class="mr-3" color="#81C24B">{{link.icon}}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                {{link.label}}
                              </v-list-item-title>
                            </v-list-item>

                            <!-- User Dashboard Button -->
                            <v-list-item 
                            v-if="isAuth && userMenu"
                            to="/overview/user/dashboard/en"
                            link>
                              <v-list-item-icon>
                                <v-icon class="mr-3" color="#81C24B">mdi-home-city</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Dashboard
                              </v-list-item-title>
                            </v-list-item>

                            <!-- Retailer Dashboard Button -->
                            <v-list-item 
                            v-if="isAuth && retailerMenu"
                            to="/overview/retailer_dashboard/home"
                            link>
                              <v-list-item-icon>
                                <v-icon class="mr-3" color="#81C24B">mdi-home-city</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Dashboard
                              </v-list-item-title>
                            </v-list-item>

                            <!-- Manufacturer Dashboard Button -->
                            <v-list-item 
                            v-if="isAuth && manufactureMenu"
                            to="/overview/manufactures_dashboard/en/home"
                            link>
                              <v-list-item-icon>
                                <v-icon class="mr-3" color="#81C24B">mdi-home-city</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Dashboard
                              </v-list-item-title>
                            </v-list-item>

                            <!-- distributor Dashboard Button -->
                            <v-list-item 
                            v-if="isAuth && distributorMenu"
                            to="/overview/distributor_dashboard/en/home"
                            link>
                              <v-list-item-icon>
                                <v-icon class="mr-3" color="#81C24B">mdi-home-city</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Dashboard
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="handleLogout" link v-if="isAuth == false">
                              <v-list-item-icon>
                                <v-icon class="mr-3" color="#81C24B">mdi-account-lock</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Sign up
                              </v-list-item-title>
                            </v-list-item>
              </v-list>
          </v-menu>

        </v-container>
      </v-app-bar>

      <!-- Admin Header Menu Starts ------>
      <v-app-bar
        app
        color="#FFFFFF"
        height="100"
        v-if="$route.meta.AdminNavigation"
      >
        <v-row>
          <v-col cols="3" md="2" justify="center">
                <router-link to="/">
                  <v-img
                    alt="System Logo"
                    class="shrink mr-2"
                    contain
                    :src="'img/logo.png'"
                    transition="scale-transition"
                    width="100"
                  />
                </router-link>
          </v-col>
          <v-col cols="9" class="mt-3" align="right">
            <v-menu v-model="menu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    large
                    class="ml-5 hidden-sm-and-down"
                    color="#F2F2F2"
                  >
                    <v-layout>
                      <v-col cols="3">
                        <v-avatar size="36px">
                          <img
                            alt="Avatar"
                            v-if="userDetails.imagePath == null"
                            src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                          />

                          <img
                            alt="Avatar"
                            v-else
                            :src="userDetails.imagePath"
                          />
                        </v-avatar>
                      </v-col>
                      <v-col cols="8">
                        <span>{{userDetails.name}}</span><br />
                        <span style="font-size:10px;">Admin Manager</span>
                      </v-col>
                    </v-layout>
                  </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-layout>
                        <v-btn text dense>Profile</v-btn>
                      </v-layout>
                      <v-layout>
                        <v-btn text dense @click="adminLogout">Log Out</v-btn>
                      </v-layout>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-app-bar>
      <!-- Admin Header Menu Ends ------>

      <!-- Logistic Header Menu Starts ------>

      <v-app-bar
        app
        color="#FFFFFF"
        height="100"
        v-if="$route.meta.LogisticsNavigation"
      >
        <v-row>
          <v-col cols="3" md="2" justify="center">
                <router-link to="/">
                  <v-img
                    alt="System Logo"
                    class="shrink mr-2"
                    contain
                    :src="'img/logo.png'"
                    transition="scale-transition"
                    width="100"
                  />
                </router-link>
          </v-col>
          <v-col cols="9" class="mt-3" align="right">
            <v-menu v-model="menu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    large
                    class="ml-5 hidden-sm-and-down"
                    color="#F2F2F2"
                  >
                    <v-layout>
                      <v-col cols="12">
                        <span>{{logisticsDetails.contactPerson}}</span><br />
                        <span style="font-size:10px;">Logistics Manager</span>
                      </v-col>
                    </v-layout>
                  </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-layout>
                        <v-btn text dense @click="logisticsLogout">Log Out</v-btn>
                      </v-layout>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>

            <v-menu >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="hidden-md-and-up" text color="white" v-bind="attrs" v-on="on">
                  <v-icon size="45" color="#000000">
                    mdi-dots-grid
                  </v-icon>
                </v-btn>
              </template>
                <v-list>
                  <v-list-item @click="logisticsLogout">
                    <v-list-item-icon>
                      <v-icon class="mr-3" color="#81C24B">mdi-home-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Logout
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-app-bar>

      <!-- Logistic Header Menu Ends ------>

      <v-main>
        <router-view
          :key="$route.path"
          v-bind:cartItems="cartItems"
          :isAuth="this.isAuth"
        ></router-view>
      </v-main>

      <v-footer
      color="#000000"
      padless
      class="hidden-sm-and-down"
      v-if="!$route.meta.hideFooter"
      >
        <v-row
          justify="center"
        >
          <v-col cols="12">
            <v-layout class="my-4">
              <v-col cols="12" md="3" class="text-center white--text">
                <v-row>
                  <v-col cols="8" offset="4">
                    <router-link to="/" class="text-center">
                      <v-img
                        alt="System Logo"
                        class="my-5 shrink text-center"
                        contain
                        :src="'img/qikpharmaLogo.png'"
                        transition="scale-transition"
                        width="100"
                      />
                    </router-link>
                  </v-col>
                </v-row>
                
              </v-col>
              <v-col cols="12" md="3" class="white--text">
                <h5>Quick Links</h5>
                <v-layout>
                  <v-col cols="12" class="white--text">
                    <a href="/#/about-us" class="footerMenuStyle mt-3">About us</a> <br/><br/>
                    <a href="/#/contact-us" class="footerMenuStyle">Contact us</a> <br/><br/>
                    <a href="/#/refund-policy" class="footerMenuStyle">Refund Policy</a> <br/><br/>
                    <a href="/#/terms-and-conditions" class="footerMenuStyle">Terms & Conditions</a> <br/><br/>
                    <a href="/#/privacy-policy" class="footerMenuStyle">Privacy Policy</a> <br/>
                  </v-col>
                </v-layout>
              </v-col>
              <v-col cols="12" md="3" class="text-left white--text">
                <h5>Contact Information</h5>
                <v-layout>
                  <v-col cols="12" class="white--text">
                    <a href="mailto:letstalknigeria@qikpharma.com" class="footerMenuStyle">letstalk@qikpharma.com</a> <br/><br/>
                    <a href="tel:+233241917797" class="footerMenuStyle mt-3">+233-24-191-7797</a> <br/><br/>
                  
                   
                    <a
                      v-for="icon in icons"
                      :key="icon.id"
                      :href="icon.link"
                      class="mx-2"
                      target="_blank"
                    >
                      <v-btn icon class="white--text">
                        <v-icon size="24px">
                          {{ icon.icon }}
                        </v-icon>
                      </v-btn>
                    </a>

                  </v-col>
                </v-layout>
              </v-col>
              <v-col cols="12" md="3" class="text-left white--text">
                <h5>Trade Services</h5>
                <v-layout>
                  <v-col cols="12" class="white--text">
                    <a href="/#/trade-assurance" class="footerMenuStyle">Trade Assurance</a> <br/><br/>
                    <a href="/#/payment-information" class="footerMenuStyle mt-3">Making Payments</a> <br/><br/>
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
          <v-col
            cols="12"
            class="text-center white--text"
            color="#000000"
          >
            {{ new Date().getFullYear() }} — <strong>qikPharma All Rights Reserved</strong>
          </v-col>
        </v-row>
      </v-footer>

      <v-footer
        app
        bottom
        fixed
        dark
        padless
        class="hidden-md-and-up"
        v-if="!$route.meta.hideNavigation"
      >
        <v-row justify="center" no-gutters>
          <v-card flat tile class="white--text text-left">
            <v-card-text class="padding:8px !important;">

              <v-row v-if="isAuth && userMenu">
                <v-flex
                  v-for="icon in LoggedInUserLinks"
                  :key="icon.id"
                  class="linkStyle mb-3 white--text"
                >
                  <router-link
                    :to="icon.link"
                    style="text-decoration: none; color: white;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-icon size="20px" color="#7CBF46" justify="center">
                          {{ icon.icon }}
                        </v-icon>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important">{{
                        icon.text
                      }}</span>
                    </v-row>
                  </router-link>
                </v-flex>
                <v-flex class="linkStyle mb-3 white--text">
                  <router-link
                    :to="'/cart/overview'"
                    style="text-decoration: none; color: black;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-badge
                          class="mr-2"
                          :content="cartItemCount"
                          color="red"
                          :to="'/cart/overview'"
                        >
                          <v-icon size="20px" color="#7CBF46" justify="center"
                            >mdi-cart-outline</v-icon
                          >
                        </v-badge>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important;color:#ffffff;">Cart</span>
                    </v-row>
                  </router-link>
                </v-flex>
                <v-flex class="linkStyle mb-3 white--text">
                  <v-row>
                    <v-flex xs12 justify="center">
                      <v-icon
                        size="20px"
                        color="#7CBF46"
                        justify="center"
                        @click="handleLogout"
                      >
                        mdi-logout
                      </v-icon>
                    </v-flex>
                  </v-row>
                  <v-row justify="center">
                    <span style="font-size:9px !important">
                      Logout
                    </span>
                  </v-row>
                </v-flex>
              </v-row>
              
              <v-row v-if="isAuth && retailerMenu">
                <v-flex
                  v-for="icon in LoggedInRetailerLinks"
                  :key="icon.id"
                  class="linkStyle mb-3 white--text"
                >
                  <router-link
                    :to="icon.link"
                    style="text-decoration: none; color: white;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-icon size="20px" color="#7CBF46" justify="center">
                          {{ icon.icon }}
                        </v-icon>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important">{{
                        icon.text
                      }}</span>
                    </v-row>
                  </router-link>
                </v-flex>
                <v-flex class="linkStyle mb-3 white--text">
                  <router-link
                    :to="'/cart/overview'"
                    style="text-decoration: none; color: black;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-badge
                          class="mr-2"
                          :content="cartItemCount"
                          color="red"
                          :to="'/cart/overview'"
                        >
                          <v-icon size="20px" color="#7CBF46" justify="center"
                            >mdi-cart-outline</v-icon
                          >
                        </v-badge>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important;color:#ffffff;">Cart</span>
                    </v-row>
                  </router-link>
                </v-flex>
                <v-flex class="linkStyle mb-3 white--text">
                  <v-row>
                    <v-flex xs12 justify="center">
                      <v-icon
                        size="20px"
                        color="#7CBF46"
                        justify="center"
                        @click="handleLogout"
                      >
                        mdi-logout
                      </v-icon>
                    </v-flex>
                  </v-row>
                  <v-row justify="center">
                    <span style="font-size:9px !important">
                      Logout
                    </span>
                  </v-row>
                </v-flex>
              </v-row>

              <v-row v-if="isAuth && manufactureMenu">
                <v-flex
                  v-for="icon in LoggedInManfactureLinks"
                  :key="icon.id"
                  class="linkStyle mb-3 white--text"
                >
                  <router-link
                    :to="icon.link"
                    style="text-decoration: none; color: white;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-icon size="20px" color="#7CBF46" justify="center">
                          {{ icon.icon }}
                        </v-icon>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important">{{
                        icon.text
                      }}</span>
                    </v-row>
                  </router-link>
                </v-flex>
                <v-flex class="linkStyle mb-3 white--text">
                  <v-row>
                    <v-flex xs12 justify="center">
                      <v-icon
                        size="20px"
                        color="#7CBF46"
                        justify="center"
                        @click="handleLogout"
                      >
                        mdi-logout
                      </v-icon>
                    </v-flex>
                  </v-row>
                  <v-row justify="center">
                    <span style="font-size:9px !important">
                      Logout
                    </span>
                  </v-row>
                </v-flex>
              </v-row>

              <v-row v-if="isAuth && distributorMenu">
                <v-flex
                  v-for="icon in LoggedInDistributorLinks"
                  :key="icon.text"
                  class="linkStyle mb-3 white--text"
                >
                  <router-link
                    :to="icon.link"
                    style="text-decoration: none; color: white;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-icon size="20px" color="#7CBF46" justify="center">
                          {{ icon.icon }}
                        </v-icon>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important">{{
                        icon.text
                      }}</span>
                    </v-row>
                  </router-link>
                </v-flex>

                <v-flex class="linkStyle mb-3 white--text">
                  <router-link
                    :to="'/cart/overview'"
                    style="text-decoration: none; color: white;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-badge
                          class="mr-2"
                          :content="cartItemCount"
                          color="red"
                        >
                          <v-icon size="20px" color="#7CBF46" justify="center"
                            >mdi-cart-outline</v-icon
                          >
                        </v-badge>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important">Cart</span>
                    </v-row>
                  </router-link>
                </v-flex>

                <v-flex class="linkStyle mb-3 white--text">
                  <v-row>
                    <v-flex xs12 justify="center">
                      <v-icon
                        size="20px"
                        color="#7CBF46"
                        justify="center"
                        @click="handleLogout"
                      >
                        mdi-logout
                      </v-icon>
                    </v-flex>
                  </v-row>
                  <v-row justify="center">
                    <span style="font-size:9px !important">
                      Logout
                    </span>
                  </v-row>
                </v-flex>
              </v-row>

              <v-row v-if="!isAuth">
                <v-flex
                  v-for="icon in quickFooterMenu"
                  :key="icon.id"
                  class="linkStyle mb-3 white--text"
                >
                  <router-link
                    :to="icon.link"
                    style="text-decoration: none; color: white;"
                  >
                    <v-row>
                      <v-flex xs12 justify="center">
                        <v-icon size="20px" color="#7CBF46" justify="center">
                          {{ icon.icon }}
                        </v-icon>
                      </v-flex>
                    </v-row>
                    <v-row justify="center">
                      <span style="font-size:9px !important">{{
                        icon.text
                      }}</span>
                    </v-row>
                  </router-link>
                </v-flex>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-footer>

      <v-dialog v-model="dialogLocation" width="400">
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="11" align="center">
                        <v-icon size="60" color="#72BD61"
                          >mdi-map-marker</v-icon
                        >
                      </v-col>

                      <v-col cols="1" align="right">
                        <v-btn
                          icon
                          color="black"
                          @click="dialogLocation = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" align="center">
                        <p>qikPharma will like to know your current location.</p>
                        <v-btn class="buttonStyle" @click="promptUserForCoordinates()">
                          Grant Permission</v-btn
                        >
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
      </v-dialog>

    </v-app>
  </div>
</template>
<script>
import PageLoader from "./components/PageLoader.vue";

export default {
  name: "App",
  data() {
    return {
      loading: true,
      sessionTimeout: null,
      accountMenu: false,
      accountMenu2: false,
      storeOwner: false,
      dialogLocation: false,
      menu: false,
      quickMenu: [
        // { icon: "mdi-home-city", text: "Home", link: "/" },
        {
          icon: "mdi-storefront",
          text: "Stores",
          link: "/stores",
        },
        { icon: "mdi-school", text: "Consultations", link: "/consult-with-us" },
        
      ],
      LoggedInUserLinks: [
        {
          id:1,
          icon: "mdi-home-city",
          text: "Dashboard",
          link: "/overview/user/dashboard/en",
        },
        { id:2, icon: "mdi-pill", text: "Products", link: "/stores" }
      ],
      LoggedInRetailerLinks: [
        {
          id:1,
          icon: "mdi-home-city",
          text: "Dashboard",
          link: "/overview/retailer_dashboard/home",
        },
        { id:2, icon: "mdi-pill", text: "Products", link: "/stores" }
      ],
      LoggedInManfactureLinks: [
        {
          id:1,
          icon: "mdi-home-city",
          text: "Home",
          link: "/overview/manufactures_dashboard/en/home",
        },
        { id:2, icon: "mdi-pill", text: "Products", link: "/stores" },
        {
          id:3,
          icon: "mdi-comment-text",
          text: "Messages",
          link: "/retailer/message-center",
        },
      ],
      LoggedInDistributorLinks: [
        {
          id:1,
          icon: "mdi-home-city",
          text: "Home",
          link: "/overview/distributor_dashboard/en/home",
        },
        {
          id:2,
          icon: "mdi-pill",
          text: "Products",
          link: "/en/distributor/0/products",
        },
        {
          id:3,
          icon: "mdi-comment-text",
          text: "Feedback",
          link: "#",
        },
      ],
      quickFooterMenu: [
        { id:1, icon: "mdi-home-city", text: "Home", link: "/" },
        { id:2, icon: "mdi-pill", text: "Products", link: "/stores" },
        {
          id:3, 
          icon: "mdi-storefront",
          text: "Stores",
          link: "/stores",
        },
        {
          id:4,
          icon: "mdi-account-arrow-right",
          text: "Sign in",
          link: "/login",
        },
      ],
      icons: [
        { id:1, icon:"mdi-facebook", link:"https://facebook.com/qikPharma"}, 
        { id:2, icon:"mdi-twitter", link:"https://twitter.com/qikpharma"}, 
        { id:3, icon:"mdi-instagram", link:"https://instagram.com/qikPharma"} 
      ],
      links: [
        { 
          id: 1,
          label: "Home",
          link: "/" 
        }, 
        { 
          id: 2,
          label: "About Us",
          link: "/about-us" 
        }, 
        { 
          id: 3,
          label: "Contact Us",
          link: "/contact-us" 
        },
        { 
          id: 4,
          label: "Refund Policy",
          link: "/refund-policy" 
        },
        { 
          id: 5,
          label: "Terms & Conditions",
          link: "/terms-and-conditions" 
        },
        { 
          id: 6,
          label: "Privacy Policy",
          link: "/privacy-policy" 
        },
        ],
      linksMenu: [
        { 
          id: 1,
          label: "Home",
          name: "HomePage",
          icon:"mdi-home-variant"

        }, 
        { 
          id: 2,
          label: "Stores",
          name: "pharmaStores",
          icon:"mdi-storefront"
        }, 
        { 
          id: 3,
          label: "Consultations",
          name: "Consult",
          icon:"mdi-head-plus"
        }
      ],
      isAuth: null,
      userId: "",
      userMenu:null,
      manufactureMenu: null,
      distributorMenu: null,
      retailerMenu: null,
      logisticsDetails: [],
      professionalsMenu: null,
      userDetails: {
        name: "",
      },
      cartItems: [],

      productList:[],
      systemSetting: {
        systemCurrency: "GH₵",
      },
      search:''
    };
  },
  components: {
    PageLoader,
  },
  computed: {
    cartItemCount() {
      return this.$store.getters.getCartItemCount;
    }
  },
  mounted() {

    this.$store.dispatch('getCartItems');

    // Set the session timeout to 30 minutes
    const sessionTimeout = 60 * 60 * 1000; // 60 minutes in milliseconds
    // Start the session timer
    this.sessionTimeout = setInterval(() => {
      // Check if the session has expired
      if (this.isSessionExpired()) {
        // Log the user out
        this.handleLogout();
      }
    }, sessionTimeout);

    setTimeout(() => {
      this.loading = false; // Set loading to false to hide the loader when done
    }, 2000); // Replace this with your actual loading logic
  },
  created() {
    this.$store.dispatch('initializeCart');
    this.findLogisticsCompany();
    this.isAuth = this.$auth.isAuthenticated();
    this.setAuthenticatedUser();
    this.userCartDetails();
  },

  watch: {
    $route: function() {
      if (!this.$auth.getToken()) {
        this.isAuth = false;
      } else {
        this.setAuthenticatedUser();
        this.isAuth = true;
      }
    },
  },

  methods: {
    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          this.userDetails = response.data.data;
          this.$auth.setAuthenticatedUser(response); 

          if (response.data.data.cordinates == "0,0") {
            this.dialogLocation = true;
          }
          
          if (response.data.data.Role.name == "User") {
            this.userMenu = true;
          }

          if (response.data.data.Role.name == "Manufacturer") {
            this.manufactureMenu = true;
          }

          if (response.data.data.Role.name == "Distributor") {
            this.distributorMenu = true;
            this.storeOwner = true;
          }

          if (response.data.data.Role.name == "Retailer") {
            this.retailerMenu = true;
          }

          if (response.data.data.Role.name == "Professional") {
            this.professionalsMenu = true;
          }
        });
    },

    promptUserForCoordinates() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.sendLocationToServer
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },

    sendLocationToServer(position) {
      const { latitude, longitude } = position.coords;
      this.userId = localStorage.getItem("userId");
      this.$http
        .put(`${process.env.VUE_APP_URL}user/` + this.userId, {
          cordinates: `${latitude},${longitude}` 
        })
        .then((response) => {
          if (response.data.success == true) {
            this.dialogLocation = false;
          }
        });
    },

    findLogisticsCompany() {
        this.$http
        .get(`${process.env.VUE_APP_URL}logistics/` + localStorage.getItem("logisticsCompanyId"))
        .then((response) => {
          this.logisticsDetails = response.data.data;
          if(response.data.data.logisticsCompanyId == this.$route.params.logisticsCompanyId)
          {
            this.isAuth = true;
          }
        });
    },

    userCartDetails() {
      this.$http
        .get(
          `${process.env.VUE_APP_URL}cart/` + localStorage.getItem("userId")
        )
        .then((response) => {
          let recentCart = JSON.parse(localStorage.getItem("cartItems"));

          if (recentCart == null) recentCart = [];
          const cartListItems = response.data.data.CartItems;
          localStorage.setItem("cartItems", JSON.stringify(cartListItems));

          this.cartItems = JSON.parse(localStorage.getItem("cartItems"));
        });
    },

    handleLogout() {
      this.storeOwner = false;
      this.userMenu = null;
      this.manufactureMenu = null;
      this.distributorMenu = null;
      this.retailerMenu = null;
      this.$auth.destroyToken();
      localStorage.clear();
      this.$router.push("/login");
    },

    isSessionExpired() {
      // Get the expiration time from your authentication system
      const expirationTime = new Date(localStorage.getItem('expirationTime'));
      const now = new Date();
      // Compare the current time to the expiration time
      return now.getTime() > expirationTime.getTime();
    },

    adminLogout() {
      this.$auth.destroyToken();
      this.$router.push("/admin/0/login");
    },

    logisticsLogout() {
      this.$auth.destroyToken();
      this.$router.push("/logistics-login");
    },

    handleSearch() {
       this.$router.push({ path: '/product-search', query: { search: this.search }});
    }
  },
  beforeDestroy() {
    // Clear the session timer when the component is destroyed
    clearInterval(this.sessionTimeout);
  }
};
</script>
<style scoped>

span {
  text-transform: none;
}

.productContainer {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 50px;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
}

/* .menuStyle {
  background-color: transparent;
} */

.topMenu {
  color: #111212 !important;
  font-size: 14px !important;
  margin: 10px !important;
}

.topMenu2 {
  color: #ffffff !important;
  font-size: 14px !important;
  margin: 5px 20px 10px 20px !important;
}

.topMenu3 {
  color: #ffffff !important;
  font-size: 16px !important;
  margin: 0px 5px 5px 0px !important;
}

.topMenuIcon {
  color: #c4c4c4 !important;
  font-size: 14px !important;
}

.textStyle {
  text-align: center;
}

.linkStyle {
  flex-basis: 25%;
  flex-grow: 0;
  max-width: 25%;
}

.footStylePadding {
  padding: 60px;
}

.footerMenuStyle {
  text-decoration: none;
  color: #ffffff;
  margin-top:50px;
}
</style>
